// import tr_TR from './tr-TR/';
// import ar from './ar/';
import de from './de';
import en_US from './en-US/';
// import es from './es/';
// import fr from './fr/';
// import ja_JP from './ja-JP/';
// import nl from './nl/';
// import pt_BR from './pt-BR/';
// import vi from './vi/';
// import zh from './zh/';
import uk from './uk/';

export default {
  ...uk,
  ...en_US,
  // ...ar,
  // ...tr_TR,
  ...de,
  // ...es,
  // ...fr,
  // ...ja_JP,
  // ...nl,
  // ...pt_BR,
  // ...vi,
  // ...zh,
};
